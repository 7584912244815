<template>

  <b-overlay
    :show="loading"
    rounded="sm"
  >

    <b-card title="Add new configuration">
      <b-form @submit.prevent>
        <b-row>

          <b-col cols="6">
            <b-form-group
              label="Config Name"
              label-for="v-configName"
            >
              <b-form-input
                id="v-sku"
                v-model="configuration.configName"
                placeholder="Config Name"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Channel"
              label-for="v-channel"
            >
              <b-form-select
                v-model="configuration.channel"
                :options="channelOptions"
                required
              />

            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="SKU Group"
              label-for="v-skuGroup"
            >
              <b-form-input
                id="v-sku"
                v-model="configuration.skuGroup"
                placeholder="SKU Group"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="SKU"
              label-for="v-sku"
            >
              <b-form-input
                id="v-sku"
                v-model="configuration.sku"
                placeholder="SKU"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Value"
              label-for="v-value"
            >
              <b-form-input
                id="v-value"
                v-model="configuration.value"
                placeholder="Value"
                required
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="loading"
              @click="saveConfiguration"
            >
              Add
            </b-button>

            <router-link :to="{name:'configurations'}">

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >

                Back
              </b-button>

            </router-link>

          </b-col>
        </b-row>
      </b-form>

    </b-card>

  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BForm, BButton,
  BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BOverlay,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      configuration: {},
      channelSelected: null,
      channelOptions: [
        { value: 'Amazon', text: 'Amazon' },
        { value: 'Website', text: 'Website' },

      ],
    }
  },
  methods: {
    saveConfiguration() {
      this.loading = true

      this.$store.dispatch('configuration/create', this.configuration)
        .then(result => {
          this.$router.push({ name: 'configurations' })
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while saving', 'danger')
        }).finally(() => {
          this.loading = false
        })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
